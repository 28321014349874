import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore as createStore, historys } from "./store/index";
import { ConnectedRouter } from "connected-react-router";
import "antd-mobile/es/global";
import { useCookie } from "react-use";

const store = createStore();
const Login = lazy(() => import("./page/login"));
const Results = lazy(() => import("./page/results"));
const SingleResults = lazy(() => import("./page/single-results"));
const RankingList = lazy(() => import("./page/ranking-list"));
const PkResults = lazy(() => import("./page/pk-results"));
const PkDetails = lazy(() => import("./page/pk-details"));
const GlControl = lazy(() => import("./page/gl-control"));
const DownAppPage = lazy(() => import("./page/down-app"));
const OPage1 = lazy(() => import("./page/operation/page1"));
const OPage2 = lazy(() => import("./page/operation/page2"));
const Unlock = lazy(() => import("./page/unlock"));
const ProviderList = lazy(() => import("./page/provider/list"));
const ProviderDetails = lazy(() => import("./page/provider/details"));
const Statistics = lazy(() => import("./page/provider/statistics"));
const WordHistory = lazy(() => import("./page/provider/word-history"));
const Summarize = lazy(() => import("./page/provider/summarize"));
const Invite = lazy(() => import("./page/provider/invite"));
const TeaList = lazy(() => import("./page/provider/tea-list"));
const TWordHistory = lazy(() => import("./page/provider/tWord-history"));
const SummarizeDay = lazy(() => import("./page/provider/summarize-day"));
const userListDay = lazy(() => import("./page/provider/user-list-day"));
const DetailsDay = lazy(() => import("./page/provider/details-day"));
const PlanList = lazy(() => import("./page/provider/plan-list"));
const UserList = lazy(() => import("./page/member/user-list"));
const BatchUser = lazy(() => import("./page/member/batch-user"));
const MemberDetails = lazy(() => import("./page/member/details"));
const AddSchool = lazy(() => import("./page/member/add-school"));
const TeaAdmin = lazy(() => import("./page/pc/tea-admin"));
const ChooseGradetype = lazy(() => import("./page/wordking/choose-gradetype"));
const ChooseMaterial = lazy(() => import("./page/wordking/choose-material"));
const ChooseWord = lazy(() => import("./page/wordking/choose-word"));
const QuestionsTest = lazy(() => import("./page/wordking/questions/test"));
const Gather = lazy(() => import("./page/wordking/gather"));
const TestResults = lazy(() => import("./page/wordking/test-results"));
const CompareTable = lazy(() => import("./page/wordking/compare-table"));


function App() {
  const [serviceToken, updateCookie] = useCookie("serviceToken");
  // const dispatch = useDispatch();
  useEffect(() => {
    window.entryUrl = window.location.href;
    const appToken: string =
      new URLSearchParams(window.location.search).get("serviceToken") || "";
    if (appToken) {
      updateCookie(appToken, { expires: 7 });
    }
  }, [updateCookie]);
  return (
    <Provider store={store}>
      <ConnectedRouter history={historys}>
        <div className="App">
          <BrowserRouter>
            <Suspense fallback={<></>}>
              <Route path="/" exact component={Login} />
              <Route path="/results/:userId?" exact component={Results} />
              <Route
                path="/single-results/:id"
                exact
                component={SingleResults}
              />
              <Route path="/ranking-list" exact component={RankingList} />
              <Route path="/pk-results" exact component={PkResults} />
              <Route path="/pk-details/:id" exact component={PkDetails} />
              <Route path="/gl-control" exact component={GlControl} />
              <Route path="/down-app" exact component={DownAppPage} />
              <Route path="/operation/page1" exact component={OPage1} />
              <Route path="/operation/page2" exact component={OPage2} />
              <Route path="/unlock" exact component={Unlock} />
              <Route path="/provider-list" exact component={ProviderList} />
              <Route path="/provider-details/:userId" exact component={ProviderDetails} />
              <Route path="/provider-wordHistory/:userId" exact component={WordHistory} />
              <Route path="/provider-tWordHistory/:userId" exact component={TWordHistory} />
              <Route path="/provider-summarize/:batchNo" exact component={Summarize} />
              <Route path="/provider-invite/:shareCode?" exact component={Invite}/>
              <Route path="/provider-tea-list" exact component={TeaList}/>
              <Route path="/provider-summarizeDay/:userId/:dayKey/:spUserId/:source?" exact component={SummarizeDay}/>
              <Route path="/provider-userListDay/:userId" exact component={userListDay} />
              <Route path="/provider-detailsDay/:userId/:dayKey" exact component={DetailsDay} />
              <Route path="/plan-list/:userId/:source?" exact component={PlanList} />
              <Route path="/member/user-list" exact component={UserList} />
              <Route path="/member/batch-user" exact component={BatchUser} />
              <Route path="/member/details/:userId" exact component={MemberDetails} />
              <Route path="/member/add-school" exact component={AddSchool} />
              <Route path="/pc/tea-admin" exact component={TeaAdmin} />
              <Route path="/wordking/choose-gradetype" exact component={ChooseGradetype} />
              <Route path="/wordking/choose-material/:gradType/:classId?/:userId?" exact component={ChooseMaterial} />
              <Route path="/wordking/choose-word/:classId?" exact component={ChooseWord} />
              <Route path="/wordking/questions/test" exact component={QuestionsTest} />
              <Route path="/wordking/gather" exact component={Gather} />
              <Route path="/wordking/test-results" exact component={TestResults} />
              <Route path="/wordking/compare-table/:planId/" exact component={CompareTable} />
              <Route path="/provider-statistics/:userId" exact component={Statistics} />
            </Suspense>
          </BrowserRouter>
        </div>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
